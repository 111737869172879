var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-field p-col-12 p-md-12"},[_c('div',{staticClass:"p-fluid p-formgrid p-grid"},[_c('div',{staticClass:"p-mt-3 p-field p-col-12 p-md-5"},[_c('h5',{staticClass:"background-gray"},[_vm._v("Cartão de Cidadão")]),(_vm.userprofileDocs.citizenCard.url != null)?_c('ImagePreview',{attrs:{"src":_vm.userprofileDocs.citizenCard.url,"alt":"Image","width":"150","preview":""}}):_vm._e(),(_vm.inEditing)?_c('FileUpload',{ref:"uploadInput",attrs:{"mode":"basic","name":"formValuesTraining.files[]","chooseLabel":_vm.userprofileDocs.citizenCard.url != null
            ? 'Alterar Cartão de Cidadão'
            : 'Adicionar Cartão de Cidadão',"multiple":false,"accept":"image/*"},on:{"select":_vm.addCitizenCard}}):_vm._e()],1),_c('div',{staticClass:"p-mt-3 p-field p-col-12 p-md-6"},[_c('h5',{staticClass:"background-gray"},[_vm._v("Curriculum Vitae")]),(_vm.userprofileDocs.cv.url != null)?_c('embed',{attrs:{"src":_vm.userprofileDocs.cv.url,"type":"application/pdf","width":"100%","height":"200px"}}):_vm._e(),(_vm.inEditing)?_c('FileUpload',{ref:"uploadInput",attrs:{"mode":"basic","name":"formValuesTraining.files[]","chooseLabel":_vm.userprofileDocs.cv.url != null
            ? 'Alterar Curriculum Vitae'
            : 'Adicionar Curriculum Vitae',"multiple":false,"accept":"application/pdf"},on:{"select":_vm.addCv}}):_vm._e()],1),_c('Divider'),_c('div',{staticClass:"p-mt-3 p-field p-col-12 p-md-6"},[_c('h5',{staticClass:"background-gray"},[_vm._v("Certificado de Habilitações")]),(_vm.userprofileDocs.qualificationsCertificate.url != null)?_c('ImagePreview',{attrs:{"src":_vm.userprofileDocs.qualificationsCertificate.url,"alt":"Image","width":"150","preview":""}}):_vm._e(),(_vm.inEditing)?_c('FileUpload',{ref:"uploadInput",attrs:{"mode":"basic","name":"formValuesTraining.files[]","chooseLabel":_vm.userprofileDocs.qualificationsCertificate.url != null
            ? 'Alterar Certificado de Habilitações'
            : 'Adicionar Certificado de Habilitações',"multiple":false,"accept":"image/*"},on:{"select":_vm.addQualificationsCertificate}}):_vm._e()],1),(_vm.hasCar)?_c('div',{staticClass:"p-mt-3 p-field p-col-12 p-md-6"},[_c('h5',{staticClass:"background-gray"},[_vm._v("Declaração Automóvel")]),(_vm.userprofileDocs.carDeclaration.url != null)?_c('ImagePreview',{attrs:{"src":_vm.userprofileDocs.carDeclaration.url,"alt":"Image","width":"150","preview":""}}):_vm._e(),(_vm.inEditing)?_c('FileUpload',{ref:"uploadInput",attrs:{"mode":"basic","name":"formValuesTraining.files[]","chooseLabel":_vm.userprofileDocs.carDeclaration.url != null
            ? 'Alterar Declaração Automóvel'
            : 'Adicionar Declaração Automóvel',"multiple":false,"accept":"image/*"},on:{"select":_vm.addCarDeclaration}}):_vm._e()],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }