<template>
  <div class="p-field p-col-12 p-md-12">
    <ConfirmDialog group="confirm-experience" />
    <DataTable
      :value="userProfessionalExperience"
      class="p-datatable-sm p-datatable-responsive"
    >
      <template #header>
        <div class="p-fluid p-formgrid p-grid">
          <div style="text-align: left" class="p-field p-md-10">
            <h5>Experiência Profissional</h5>
          </div>
          <div style="text-align: right" class="p-field p-md-2">
            <Button
              v-if="inEditing"
              icon="pi pi-plus"
              class="p-button-rounded p-button-success p-button-text p-button-lg"
              @click="addItemExperience"
            />
          </div>
        </div>
      </template>
      <Column field="period" header="Período"></Column>
      <Column field="entity" header="Entidade"></Column>
      <Column field="activity" header="Actividades Desenvolvidas"></Column>
      <Column>
        <template #body="slotProps">
          <Button
            v-if="!inEditing"
            icon="pi pi-eye"
            class="p-button-rounded p-button-success p-button-text"
            @click="editExperience(slotProps.data)"
          />
          <Button
            v-if="inEditing"
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-button-text"
            @click="editExperience(slotProps.data)"
          />
          <Button
            v-if="inEditing"
            icon="pi pi-trash"
            class="p-button-sm p-button-rounded p-button-danger p-button-text"
            style="text-align: right"
            @click="removeItemExperience(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>

    <Dialog
      :visible.sync="showFormExperience"
      :style="{ width: $isMobile() ? '100vw' : '50vw' }"
      :header="'Experiência Profissional'"
      :modal="true"
      :contentStyle="{ overflow: 'hide' }"
    >
      <form
        name="literaryAbilitiesForm"
        class="p-col-12"
        @submit.prevent="saveItemExperience"
        style="min-height: 35vh"
      >
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-mt-3 p-field p-col-12 p-md-6">
            <span class="p-float-label">
              <InputText
                type="text"
                name="period"
                v-bind:class="[
                  { 'p-error': errors.has('period') },
                  'form-control',
                ]"
                v-model="formValuesExperience.period"
                v-validate="'required'"
                :disabled="!inEditing"
              />
              <label for="inputtext">Ano</label>
            </span>
            <small v-if="errors.has('period')" class="p-error" role="alert"
              >Ano é obrigatório</small
            >
          </div>

          <div class="p-mt-3 p-field p-col-12 p-md-6">
            <span class="p-float-label">
              <InputText
                type="text"
                name="entity"
                v-bind:class="[
                  { 'p-error': errors.has('entity') },
                  'form-control',
                ]"
                v-model="formValuesExperience.entity"
                v-validate="'required'"
                :disabled="!inEditing"
              />
              <label for="inputtext">Entidade</label>
            </span>
            <small v-if="errors.has('entity')" class="p-error" role="alert"
              >Entidade é obrigatório</small
            >
          </div>

          <div class="p-mt-3 p-field p-col-12 p-md-12">
            <span class="p-float-label">
              <Textarea
                type="text"
                name="activity"
                v-bind:class="[
                  { 'p-error': errors.has('activity') },
                  'form-control',
                ]"
                v-model="formValuesExperience.activity"
                v-validate="'required'"
                rows="5"
                :autoResize="true"
                :disabled="!inEditing"
              />
              <label for="inputtext">Actividades Desenvolvidas</label>
            </span>
            <small v-if="errors.has('activity')" class="p-error" role="alert"
              >Actividades Desenvolvidas é obrigatório</small
            >
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          v-if="inEditing"
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-text p-button-success"
          @click="saveItemExperience"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-text p-button-danger"
          @click="closeFormExperience"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
export default {
  name: "ProfileLiteraryAbilities",
  props: ["userProfessionalExperience", "inEditing", "removed"],
  data() {
    return {
      showFormExperience: false,
      formValuesExperience: {},
    };
  },
  created() {},
  methods: {
    addItemExperience() {
      this.formValuesExperience = { id: new Date().getTime(), new: true };
      this.showFormExperience = true;
    },
    closeFormExperience() {
      this.$validator.pause();
      this.$validator.reset();
      this.showFormExperience = false;
      this.formValuesExperience = {};
    },
    editExperience(item) {
      this.formValuesExperience = { ...item };
      this.formValuesExperience.edited = true;
      this.showFormExperience = true;
    },
    removeItemExperience(item) {
      this.$confirm.require({
        key: "confirm-experience",
        header: "Apagar Experiência Profissional",
        message:
          "Tem a certeza que pretende apagar esta experiência profissional?",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-text p-button-success p-button p-component",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-text p-button-danger p-button p-component",
        accept: () => {
          if (!item.new != undefined) {
            this.removed.push({ id: item.id });
          }
          const areYou = (itemAux) => {
            return itemAux.id == item.id;
          };
          let index = this.userProfessionalExperience.findIndex(areYou);

          if (index >= 0) {
            this.userProfessionalExperience.splice(index, 1);
          }
        },
        reject: () => {},
      });
    },
    saveItemExperience() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }

        this.$validator.pause();
        this.$validator.reset();

        if (this.formValuesExperience.edited != undefined) {
          const areYou = (itemAux) => {
            return itemAux.id == this.formValuesExperience.id;
          };
          let index = this.userProfessionalExperience.findIndex(areYou);

          if (index >= 0) {
            this.userProfessionalExperience[index] = {
              ...this.formValuesExperience,
            };
          }

          return this.closeFormExperience();
        }
        if (this.formValuesExperience.new != undefined) {
          this.userProfessionalExperience.push(this.formValuesExperience);
          return this.closeFormExperience();
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

</style>
