<template>
  <div class="p-field p-col-12 p-md-12">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-mt-3 p-field p-col-12 p-md-5">
        <h5 class="background-gray">Cartão de Cidadão</h5>
        <ImagePreview
          v-if="userprofileDocs.citizenCard.url != null"
          :src="userprofileDocs.citizenCard.url"
          alt="Image"
          width="150"
          preview
        />
        <FileUpload
          v-if="inEditing"
          ref="uploadInput"
          mode="basic"
          name="formValuesTraining.files[]"
          :chooseLabel="
            userprofileDocs.citizenCard.url != null
              ? 'Alterar Cartão de Cidadão'
              : 'Adicionar Cartão de Cidadão'
          "
          :multiple="false"
          accept="image/*"
          @select="addCitizenCard"
        />
      </div>
      <div class="p-mt-3 p-field p-col-12 p-md-6">
        <h5 class="background-gray">Curriculum Vitae</h5>
        <embed
          v-if="userprofileDocs.cv.url != null"
          :src="userprofileDocs.cv.url"
          type="application/pdf"
          width="100%"
          height="200px"
        />
        <FileUpload
          v-if="inEditing"
          ref="uploadInput"
          mode="basic"
          name="formValuesTraining.files[]"
          :chooseLabel="
            userprofileDocs.cv.url != null
              ? 'Alterar Curriculum Vitae'
              : 'Adicionar Curriculum Vitae'
          "
          :multiple="false"
          accept="application/pdf"
          @select="addCv"
        />
      </div>
      <Divider />
      <div class="p-mt-3 p-field p-col-12 p-md-6">
        <h5 class="background-gray">Certificado de Habilitações</h5>
        <ImagePreview
          v-if="userprofileDocs.qualificationsCertificate.url != null"
          :src="userprofileDocs.qualificationsCertificate.url"
          alt="Image"
          width="150"
          preview
        />
        <FileUpload
          v-if="inEditing"
          ref="uploadInput"
          mode="basic"
          name="formValuesTraining.files[]"
          :chooseLabel="
            userprofileDocs.qualificationsCertificate.url != null
              ? 'Alterar Certificado de Habilitações'
              : 'Adicionar Certificado de Habilitações'
          "
          :multiple="false"
          accept="image/*"
          @select="addQualificationsCertificate"
        />
      </div>
      <div class="p-mt-3 p-field p-col-12 p-md-6" v-if="hasCar">
        <h5 class="background-gray">Declaração Automóvel</h5>
        <ImagePreview
          v-if="userprofileDocs.carDeclaration.url != null"
          :src="userprofileDocs.carDeclaration.url"
          alt="Image"
          width="150"
          preview
        />
        <FileUpload
          v-if="inEditing"
          ref="uploadInput"
          mode="basic"
          name="formValuesTraining.files[]"
          :chooseLabel="
            userprofileDocs.carDeclaration.url != null
              ? 'Alterar Declaração Automóvel'
              : 'Adicionar Declaração Automóvel'
          "
          :multiple="false"
          accept="image/*"
          @select="addCarDeclaration"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProfileDocs",
  props: ["userprofileDocs", "inEditing", "hasCar"],
  data() {
    return {};
  },
  methods: {
    addCitizenCard(event) {
      this.userprofileDocs.citizenCard.file = event.files[0];
      this.userprofileDocs.citizenCard.url = event.files[0].objectURL;
    },
    addCv(event) {
      this.userprofileDocs.cv.file = event.files[0];
      this.userprofileDocs.cv.url = event.files[0].objectURL;
    },
    addQualificationsCertificate(event) {
      this.userprofileDocs.qualificationsCertificate.file = event.files[0];
      this.userprofileDocs.qualificationsCertificate.url =
        event.files[0].objectURL;
    },
    addCarDeclaration(event) {
      this.userprofileDocs.carDeclaration.file = event.files[0];
      this.userprofileDocs.carDeclaration.url = event.files[0].objectURL;
    },
  },
};
</script>
<style>
.background-gray{
  background-color: #efefef;
}
</style>
