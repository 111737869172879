<template>
  <div class="p-field p-col-12 p-md-12">
    <ConfirmDialog group="confirm-training" />
    <DataTable
      :value="userAdditionalTraining"
      class="p-datatable-sm p-datatable-responsive"
    >
      <template #header>
        <div class="p-fluid p-formgrid p-grid">
          <div style="text-align: left" class="p-field p-md-10">
            <h5>Formação Complementar</h5>
          </div>
          <div style="text-align: right" class="p-field p-md-2">
            <Button
              v-if="inEditing"
              icon="pi pi-plus"
              class="p-button-rounded p-button-success p-button-text p-button-lg"
              @click="addItemTraining"
            />
          </div>
        </div>
      </template>
      <Column field="year" header="Ano"></Column>
      <Column field="duration" header="Duração"></Column>
      <Column field="entity" header="Entidade"></Column>
      <Column field="course" header="Curso/Ação"></Column>
      <Column>
        <template #body="slotProps">
          <Button
            v-if="!inEditing"
            icon="pi pi-eye"
            class="p-button-rounded p-button-success p-button-text"
            @click="editTraining(slotProps.data)"
          />
          <Button
            v-if="inEditing"
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-button-text"
            @click="editTraining(slotProps.data)"
          />
          <Button
            v-if="inEditing"
            icon="pi pi-trash"
            class="p-button-sm p-button-rounded p-button-danger p-button-text"
            @click="removeTraining(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>

    <Dialog
      :visible.sync="showFormTraining"
      :style="{ width: $isMobile() ? '100vw' : '50vw' }"
      :header="'Formação Complementar'"
      :modal="true"
      :contentStyle="{ overflow: 'hide' }"
    >
      <form
        name="additionalTrainingForm"
        class="p-col-12"
        @submit.prevent="saveTraining"
        style="min-height: 35vh"
      >
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-mt-3 p-field p-col-12 p-md-6">
            <span class="p-float-label">
              <InputText
                type="text"
                name="year"
                v-bind:class="[
                  { 'p-error': errors.has('year') },
                  'form-control',
                ]"
                v-model="formValuesTraining.year"
                v-validate="'required'"
                :disabled="!inEditing"
              />
              <label for="inputtext">Ano</label>
            </span>
            <small v-if="errors.has('year')" class="p-error" role="alert"
              >Ano é obrigatório</small
            >
          </div>

          <div class="p-mt-3 p-field p-col-12 p-md-6">
            <span class="p-float-label">
              <InputText
                type="text"
                name="duration"
                v-bind:class="[
                  { 'p-error': errors.has('duration') },
                  'form-control',
                ]"
                v-model="formValuesTraining.duration"
                v-validate="'required'"
                :disabled="!inEditing"
              />
              <label for="inputtext">Duração</label>
            </span>
            <small v-if="errors.has('duration')" class="p-error" role="alert"
              >Duração é obrigatório</small
            >
          </div>

          <div class="p-mt-3 p-field p-col-12 p-md-6">
            <span class="p-float-label">
              <InputText
                type="text"
                name="entity"
                v-bind:class="[
                  { 'p-error': errors.has('entity') },
                  'form-control',
                ]"
                v-model="formValuesTraining.entity"
                v-validate="'required'"
                :disabled="!inEditing"
              />
              <label for="inputtext">Entidade</label>
            </span>
            <small v-if="errors.has('entity')" class="p-error" role="alert"
              >Entidade é obrigatório</small
            >
          </div>

          <div class="p-mt-3 p-field p-col-12 p-md-6">
            <span class="p-float-label">
              <InputText
                type="text"
                name="course"
                v-bind:class="[
                  { 'p-error': errors.has('course') },
                  'form-control',
                ]"
                v-model="formValuesTraining.course"
                v-validate="'required'"
                :disabled="!inEditing"
              />
              <label for="inputtext">Curso</label>
            </span>
            <small v-if="errors.has('course')" class="p-error" role="alert"
              >Curso é obrigatório</small
            >
          </div>

          <div class="p-mt-3 p-field p-col-12 p-md-6">
            <FileUpload
              v-if="inEditing"
              ref="uploadInput"
              mode="basic"
              name="formValuesTraining.files[]"
              chooseLabel="Adicionar Certificado"
              :multiple="false"
              accept="image/*"
              @select="addFileToTraining"
            />
          </div>
        </div>
        <ImagePreview
          v-if="formValuesTraining.url != ''"
          :src="formValuesTraining.url"
          alt="Image"
          width="250"
          preview
        />
      </form>
      <template #footer>
        <Button
          v-if="inEditing"
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-text p-button-success"
          @click="saveTraining"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-text p-button-danger"
          @click="closeForm"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
export default {
  name: "ProfileAdditionalTraining",
  props: ["userAdditionalTraining", "inEditing", "removed"],
  data() {
    return {
      showFormTraining: false,
      formValuesTraining: {},
    };
  },
  created() {},
  methods: {
    addItemTraining() {
      this.formValuesTraining = {
        id: new Date().getTime(),
        new: true,
        url: "",
      };
      this.showFormTraining = true;
    },
    closeForm() {
      this.$validator.pause();
      this.$validator.reset();
      this.showFormTraining = false;
      this.formValuesTraining = {};
    },
    editTraining(item) {
      this.formValuesTraining = { ...item };
      this.formValuesTraining.edited = true;
      this.showFormTraining = true;
    },
    removeTraining(item) {
      this.$confirm.require({
        key: "confirm-training",
        header: "Apagar Formação Complementar",
        message:
          "Tem a certeza que pretende apagar esta formação complementar?",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-text p-button-success p-button p-component",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-text p-button-danger p-button p-component",
        accept: () => {
          if (!item.new != undefined) {
            this.removed.push({ id: item.id });
          }
          const areYou = (itemAux) => {
            return itemAux.id == item.id;
          };
          let index = this.userAdditionalTraining.findIndex(areYou);

          if (index >= 0) {
            this.userAdditionalTraining.splice(index, 1);
          }
        },
        reject: () => {},
      });
    },
    saveTraining() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }

        this.$validator.pause();
        this.$validator.reset();

        if (this.$refs.uploadInput.files.length > 0) {
          this.formValuesTraining.file = this.$refs.uploadInput.files[0];
        }

        if (this.formValuesTraining.edited != undefined) {
          const areYou = (itemAux) => {
            return itemAux.id == this.formValuesTraining.id;
          };
          let index = this.userAdditionalTraining.findIndex(areYou);

          if (index >= 0) {
            this.userAdditionalTraining[index] = { ...this.formValuesTraining };
          }

          return this.closeForm();
        }
        if (this.formValuesTraining.new != undefined) {
          this.userAdditionalTraining.push(this.formValuesTraining);
          return this.closeForm();
        }
      });
    },
    addFileToTraining(event) {
      this.formValuesTraining.url = event.files[0].objectURL;
    },
  },
};
</script>
<style scoped lang="scss">
.p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

.p-column-filter {
  width: 100%;
}
</style>
